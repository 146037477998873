<script setup>
import { IonRadioGroup, IonRadio, IonIcon, IonItem } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useUserSettingsStore } from "../store/userSettingsStore";
import { DebugLevel, appLog } from "../functions/Logging";
import { moonOutline, moon, phonePortraitOutline } from "ionicons/icons";
import BaseSettingList from "./base/BaseSettingList.vue";

// pinia Store - get userSettings from Store
const { darkMode } = storeToRefs(useUserSettingsStore());
const compName = "[VarusSettingsDarkMode]";

const modes = [
  {
    name: "light",
    value: false,
    icon: moonOutline
  },
  {
    name: "dark",
    value: true,
    icon: moon
  },
  {
    name: "system",
    value: "system",
    icon: phonePortraitOutline
  }
];


appLog(DebugLevel.DEBUG, compName, "darkMode: ", darkMode.value);

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
// Listen for changes to the prefers-color-scheme media query
prefersDark.addEventListener('change', (mediaQuery) => toggleDarkTheme(mediaQuery.matches));
// Add or remove the "dark" class on the document body
const toggleDarkTheme = (shouldAdd) => {
  document.body.classList.toggle('dark', shouldAdd);
};

function handleChange(ev) {
  let darkModeEnabled = ev.detail.value;
  // Pinia change - in this case, must be a direct change (not a patch  change)
  darkMode.value = darkModeEnabled;
  if (darkModeEnabled == "system") {
    darkModeEnabled = prefersDark.matches;
  }
  document.body.classList.toggle('dark', darkModeEnabled);
}


// Initialize the dark theme based on the initial
// value of the prefers-color-scheme media query
//
// toggleDarkTheme(prefersDark.matches);
// console.log('prefersDark.matches:', prefersDark.matches);


///////////////////

</script>

<template>
  <!-- Dark Mode -->
  <base-setting-list :setting-title="$t('ui.settings.contrastModes.title')">
    <ion-radio-group :value="darkMode" @ionChange="handleChange($event)">
      <ion-item v-for="mode in modes" :key="mode.name">
        <ion-icon aria-hidden="true" :icon="mode.icon" slot="start"></ion-icon>
        <ion-radio :value="mode.value">{{ $t('ui.settings.contrastModes.' + mode.name) }}</ion-radio><br />
      </ion-item>
    </ion-radio-group>
  </base-setting-list>
</template>
<style>
ion-item {
  --transition: none;
}
</style>
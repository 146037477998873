function julianIntToDate(JD) {
  var y = 4716;
  var v = 3;
  var j = 1401;
  var u = 5;
  var m = 2;
  var s = 153;
  var n = 12;
  var w = 2;
  var r = 4;
  var B = 274277;
  var p = 1461;
  var C = -38;
  var f = JD + j + Math.floor((Math.floor((4 * JD + B) / 146097) * 3) / 4) + C;
  var e = r * f + v;
  var g = Math.floor((e % p) / r);
  var h = u * g + w;
  var D = Math.floor((h % s) / u) + 1;
  var M = ((Math.floor(h / s) + m) % n) + 1;
  var Y = Math.floor(e / p) - y + Math.floor((n + m - M) / n);
  return new Date(Y, M - 1, D);
}

class CDate {
  constructor(calendar, year, month, day) {
    this._calendar = calendar;
    this._year = year;
    this._month = month;
    this._day = day;
  }

  newDate(year, month, day) {
    return this._calendar.newDate(typeof year === "undefined" || year === null ? this : year, month, day);
  }

  year(year) {
    return arguments.length === 0 ? this._year : this.set(year, "y");
  }

  month(month) {
    return arguments.length === 0 ? this._month : this.set(month, "m");
  }

  day(day) {
    return arguments.length === 0 ? this._day : this.set(day, "d");
  }

  date(year, month, day) {
    if (!this._calendar.isValid(year, month, day)) {
      this._year = year;
      this._month = month;
      this._day = day;
      return this;
    }
  }

  leapYear() {
    return this._calendar.leapYear(this);
  }

  epoch() {
    return this._calendar.epoch(this);
  }

  monthOfYear() {
    return this._calendar.monthOfYear(this);
  }

  weekOfYear() {
    return this._calendar.weekOfYear(this);
  }

  daysInYear() {
    return this._calendar.daysInYear(this);
  }

  dayOfYear() {
    return this._calendar.dayOfYear(this);
  }

  daysInMonth() {
    return this._calendar.daysInMonth(this);
  }

  dayOfWeek() {
    return this._calendar.dayOfWeek(this);
  }

  weekDay() {
    return this._calendar.weekDay(this);
  }

  add(offset, period) {
    return this._calendar.add(this, offset, period);
  }

  set(value, period) {
    return this._calendar.set(this, value, period);
  }

  compareTo(date) {
    if (this._calendar.name !== date._calendar.name) {
      throw "Invalid comparison";
    }

    let c =
      this._year !== date._year
        ? this._year - date._year
        : this._month !== date._month
        ? this.monthOfYear() - date.monthOfYear()
        : this._day - date._day;
    return c === 0 ? 0 : c < 0 ? -1 : 1;
  }

  calendar() {
    return this._calendar;
  }

  toJD() {
    return this._calendar.toJD(this);
  }

  fromJD(jd) {
    return this._calendar.fromJD(jd);
  }

  toJSDate() {
    return julianIntToDate(this.toJD());
    // return this._calendar.toJSDate();
  }

  fromJSDate(jsd) {
    return this._calendar.fromJSDate(jsd);
  }
}

export default CDate;

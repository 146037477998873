<script>
export default {
  name: "BaseLayout",
};
</script>
<script setup>
import {
  IonMenuButton,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonModal,
} from "@ionic/vue";
import { DebugLevel, appLog } from "@/functions/Logging";
import { hooks } from "@/functions/Hooks";
import { chevronBackOutline, eyeSharp } from "ionicons/icons";
import { ref } from "vue";
import { useRoute } from "vue-router";
import VarusSettingsFontSize from "../VarusSettingsFontSize.vue";
import VarusSettingsDarkMode from "../VarusSettingsDarkMode.vue";

// eslint-disable-next-line no-unused-vars
import VarusChurchSeasonSwitch from "../VarusChurchSeasonSwitch.vue";

// Pinia - ScrollStore
import { useScrollStore } from "@/store/scrollStore.js";
const scrollStore = useScrollStore();

// Logging
const compName = "[BaseLayout]";

// PROPS
defineProps({
  pageTitle: String,
  displayBackLink: String,
  pageDefaultBackLink: String,
  useHistory: Boolean,
});

// Modal
const isOpen = ref(false);
const setOpen = (open) => (isOpen.value = open);

function logScrolling(event) {
  scrollStore.YposMenuMain = event.detail.currentY;
  appLog(DebugLevel.SCROLL, compName, "Scrolling to YposMenuMain: " + event.detail.currentY);
}

// Hooks
hooks(compName);
</script>

<template>
  <ion-page>
    <ion-header :translucent="true" mode="md">
      <ion-toolbar class="header">
        <ion-buttons slot="start">
          <ion-menu-button menu="left-menu"></ion-menu-button>
          <ion-back-button
            v-if="useHistory && displayBackLink === 'true'"
            :default-href="pageDefaultBackLink"
          ></ion-back-button>
          <ion-button v-if="!useHistory && displayBackLink === 'true'" :router-link="pageDefaultBackLink">
            <ion-icon slot="start" :icon="chevronBackOutline" />
          </ion-button>
        </ion-buttons>
        <ion-title id="headerTitle">{{ pageTitle }}</ion-title>

        <!--varus-church-season-switch/-->

        <!-- Eye Icon for View Settings -->
        <ion-icon
          v-if="useRoute().meta.showDisplayOptionsIcon"
          slot="end"
          :icon="eyeSharp"
          expand="block"
          @click="setOpen(true)"
          style="margin-right: 60px"
        />
        <ion-modal :is-open="isOpen">
          <ion-header>
            <ion-toolbar class="header">
              <ion-buttons slot="end"> <ion-button @click="setOpen(false)">X</ion-button></ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
            <VarusSettingsFontSize />
            <VarusSettingsDarkMode />
          </ion-content>
        </ion-modal>

        <ion-buttons slot="end">
          <ion-menu-button menu="right-menu"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true" @ionScroll="logScrolling($event)">
      <slot />
    </ion-content>
  </ion-page>
</template>
<style scoped>
.header {
  color: white;
  background-color: transparent;
}
ion-modal {
  --width: 400px;
  --height: 50%;
}
</style>

// Coptic Date Services using iText
import { CopticDate } from "ilib-es6";

/**
 * converts the given coptic-date to a gregorian javascript date object
 *
 * @param {*} copticYear
 * @param {*} copticMonth
 * @param {*} copticDay
 * @returns
 */
export function copticDate2gregorianDate(copticYear, copticMonth, copticDay) {
  const coptDate = new CopticDate({
    year: copticYear,
    month: copticMonth,
    day: copticDay,
  });
  return coptDate.getJSDate();
}

/**
 * returns the day of the week (0 to 6) of the given coptic date (Sunday = 0, Monday = 1, ...)
 *
 * @param {*} cYear
 * @param {*} cMonth
 * @param {*} cDay
 */
export function getWeekDayOfCopticDate(cYear, cMonth, cDay) {
  return copticDate2gregorianDate(cYear, cMonth, cDay).getDay();
}

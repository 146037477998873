import { getDay, getMonth, getYear, getWeekday, getMonthName } from "../services/DateFormatService";
import { DebugLevel, appLog } from "../functions/Logging";
const STYLE = "short";
const compName = "CalendarDate";

const monthD = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
const monthA = [
  "يناير",
  "فبراير",
  "مارس",
  "أبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];
// Coptic Month Names - English Transliteration
const monthC1 = [
  "Thout",
  "Paopi",
  "Hathor",
  "Koiak",
  "Tobi",
  "Meschir",
  "Paremhat",
  "Paremoude",
  "Paschons",
  "Paoni",
  "Epip",
  "Mesori",
];
// Coptic Month Names - German Transliteration
const monthC2 = [
  "Tut",
  "Babah",
  "Hator",
  "Kiyahk",
  "Tubah",
  "Amschir",
  "Baramhat",
  "Baramuda",
  "Baschans",
  "Ba'una",
  "Abib",
  "Misra",
];

const months = [];
months["deu"] = monthD;
months["ara"] = monthA;
months["cop1"] = monthC1;
months["cop2"] = monthC2;

export function calendarDate(language, calendar, date) {
  const year = getYear(language, calendar, date);
  const month = getMonth(language, calendar, date);
  const day = getDay(language, calendar, date);
  let mnShort = getMonthName(language, calendar, date, STYLE);
  let mn = getMonthName(language, calendar, date);
  const wd = getWeekday(language, calendar, date);
  const wdShort = getWeekday(language, calendar, date, STYLE);

  // coptic month fix
  if (calendar == "coptic") {
    // Check if not a Coptic Month name is displayed
    const monthIndex1 = months[language].findIndex((monthElement) => monthElement === mnShort);
    appLog(DebugLevel.INFO, compName, "language: " + language);
    appLog(DebugLevel.INFO, compName, "monthIndex1: " + monthIndex1);
    appLog(DebugLevel.INFO, compName, "mnShort: " + mnShort);
    if (monthIndex1 > -1) {
      // Change to Coptic Month Name - German Transliteration
      const coptMonth = months["cop2"][monthIndex1];
      mnShort = coptMonth;
      mn = coptMonth;
    }
    // Change from English to German Transliteration
    // const monthIndex = months["cop1"].findIndex((monthElement) => monthElement === mnShort);
    // if (monthIndex > -1) {
    //   const coptMonth = months["cop2"][monthIndex];
    //   mnShort = coptMonth;
    //   mn = coptMonth;
    // }
  }

  return {
    date: date,
    y: year,
    m: month,
    d: day,
    mn: mn,
    mnShort: mnShort,
    wd: wd,
    wdShort: wdShort,
    formatted: wdShort + ", " + day + ". " + mnShort + " " + year,
    formatted2: day + ". " + mnShort,
    formatted3: day + ". " + mnShort + " (" + day + "." + month + ")",
  };
}

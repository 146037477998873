const getYear = function (locale, calendar, date = new Date()) {
  return new Intl.DateTimeFormat(locale, { calendar: calendar, year: "numeric" }).format(date).replace(" ERA1", "");
};

const getMonth = function (locale, calendar, date = new Date()) {
  return new Intl.DateTimeFormat(locale, { calendar: calendar, month: "numeric" }).format(date);
};

const getMonthName = function (locale, calendar, date = new Date(), style = "long") {
  return new Intl.DateTimeFormat(locale, { calendar: calendar, month: style }).format(date);
};

const getDay = function (locale, calendar, date = new Date()) {
  return new Intl.DateTimeFormat(locale, { calendar: calendar, day: "numeric" }).format(date);
};

const getWeekday = function (locale, calendar, date = new Date(), style = "long") {
  return new Intl.DateTimeFormat(locale, { calendar: calendar, weekday: style }).format(date);
};

const getCopticDatebyGreg = function (year, month, day) {
  let options = { year: "numeric", month: "long", day: "numeric", calendar: "coptic", era: "long" };
  return new Intl.DateTimeFormat("de", options).format(new Date(year, month, day));
};

export { getYear, getMonth, getMonthName, getDay, getWeekday, getCopticDatebyGreg };

import { useBrowserLocation } from "@vueuse/core";
import { isPlatform } from "@ionic/vue";

/**
 * returns true if on mobile device
 *
 * @returns
 */
export const onMobileDevice = () => {
  if (useBrowserLocation().host == "egohm.onrender.com") return false;
  if (isPlatform("mobile")) return true;
  return false;
};

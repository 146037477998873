/**
 * Calculate Orthodox Easter date using the Meeus Julian algorithm ().
 *
 * @param {*} y in valid 4-digits
 * @returns Easter date in custom date object
 */
const getCopticEasterByGregYear = (y) => {
  let d = (19 * (y % 19) + 15) % 30;
  let x = d + ((2 * (y % 4) + 4 * (y % 7) - d + 34) % 7) + 114;
  let m = ~~(x / 31);
  d = (x % 31) + 1;
  if (y > 1899 && y < 2100) {
    d += 13;
    if (m == 3 && d > 31) {
      d -= 31;
      m++;
    }
    if (m == 4 && d > 30) {
      d -= 30;
      m++;
    }
  }
  return { d, m, y };
};

export { getCopticEasterByGregYear };

import { defineStore } from "pinia";
import { VarusDate, CAL_COPT, CAL_GREG } from "../classes/VarusDate.js";
import { DebugLevel, appLog } from "../functions/Logging";

appLog(DebugLevel.INFO, "[Pinia]", "calendarStore initializing ... !");

// Locale/Language for initial Date Formatting
let lang = "deu";

export const useCalendarStore = defineStore("calendar", {
  state: () => ({
    appStateDate: new VarusDate(lang),
    // This is only testing purpose, the real season is in appStateDate.churchSeason(0)
    // this should only be used in dev, because changing it, doesn't change appStateDate
    // season: new VarusDate(lang).churchSeason[0],
  }),
  getters: {
    getDate: (state) => {
      return (cal) => state.appStateDate.getDate(cal);
    },
    getDateGreg: (state) => state.appStateDate.getDate(CAL_GREG),
    getDateCopt: (state) => state.appStateDate.getDate(CAL_COPT),
    getCopticMonth: (state) => state.appStateDate.getDate(CAL_COPT).mnShort,
    getChurchSeason: (state) => state.appStateDate.churchSeason[0],
    getTune: (state) => state.appStateDate.tune[0],
    getFasting: (state) => {
      return {
        fasting: state.appStateDate.churchSeason[0].fasting,
        fasting_fish_allowed: state.appStateDate.churchSeason[0].fasting_fish_allowed,
        fasting_strict: state.appStateDate.churchSeason[0].fasting_strict,
      };
    },
    getIsEveOf: (state) => state.appStateDate.eveOf,
    getReading: (state) => state.appStateDate.reading,
    getReadingText: (state) => {
      return (service, rType) => state.appStateDate.reading.liturgyServices[service][rType];
    },
    getReadingReference: (state) => {
      return (service, rType) => state.appStateDate.reading.liturgyServices[service][rType].reference;
    },
    getGospelAuthor: (state) => {
      return (service) => state.appStateDate.reading.liturgyServices[service]["gospel"].author;
    },
  },
  actions: {},
});

appLog(DebugLevel.INFO, "[Pinia]", "calendarStore initialized!");

import { Preferences } from "@capacitor/preferences";
import { DebugLevel, appLog } from "../functions/Logging";
import Bugsnag from '@bugsnag/js';

const fileName = "[LocalStorageService]";

export async function setItem(key, value, json = false) {
  if (json == true) {
    value = JSON.stringify(value);
  }
  appLog(DebugLevel.DEBUG, fileName, "Capacitor Preferences | Updating Key " + key + " in storage with value " + value);
  await Preferences.set({
    key: key,
    value: value,
  });
}

export async function getItem(keyName, json) {
  appLog(DebugLevel.DEBUG, fileName, "Capacitor Preferences | Reading Key " + keyName + " from Storage");
  const item = await Preferences.get({ key: keyName });
  if (json == true && item.value != "") {
    let jsonText = "";
    try {
      jsonText = JSON.parse(item.value);
    } catch (e) {
      Bugsnag.notify(e);
      appLog(DebugLevel.ERROR, fileName, "Capacitor Preferences | Invalid json in the Storage: ");
      appLog(DebugLevel.ERROR, fileName, "Capacitor Preferences | Key: " + keyName + " | " + item.value);
    }
    return jsonText;
  } else {
    return item.value;
  }
}

export async function removeItem(keyName) {
  await Preferences.remove({
    key: keyName,
  });
}

var PREFIX = "Varus";
var USER_SETTING = PREFIX + "UserSet";

export const localStorageKeyNames = {
  LANGUAGE_UI: USER_SETTING + "UILang",
  LANGUAGES_TEXT: USER_SETTING + "TextLangs",
  TRANSLATIONS: USER_SETTING + "Translations",
  DARK_MODE: USER_SETTING + "DarkMode",
  BASE_FONT_SIZE: USER_SETTING + "BaseFontSize",
};

import { getCopticEasterByGregYear } from "../services/CopticEasterService";
import { calendarDate } from "../classes/CalendarDate.js";
import { differenceInDays, addDays, isAfter } from "date-fns";
import { getKatamerosSeason, getReading } from "../seasonservice/KatamerosSeasonService.js";
import { getFeasts } from "../seasonservice/FeastService.js";
import { getFasts } from "../seasonservice/FastService.js";
import { getSeasons } from "../seasonservice/SeasonService.js";
import { getCurrentTunes } from "../seasonservice/TunesService.js";
import { DebugLevel, appLog } from "../functions/Logging";

export const CAL_COPT = "coptic";
export const CAL_GREG = "gregory";
export const STYLE = "short";

// TODO: move to configuration
const dayTranistionTime_Hour = "18";
const dayTranistionTime_Min = "00";

const fileName = "[VarusDate]";

export class VarusDate {
  constructor(language, date = new Date()) {
    appLog(DebugLevel.DEBUG, fileName, "New Date", date);

    this.language = language;
    this.date = date;
    this.dayOfWeek = date.getDay();

    // day Tranistion Time
    this.dayTranistionTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      dayTranistionTime_Hour,
      dayTranistionTime_Min
    );
    // check whether selcted date is after tranistion time
    const copticNewDay = isAfter(this.date, this.dayTranistionTime);
    this.eveOf = false;
    let dayToAddToCopticDay = 0;
    if (copticNewDay) {
      dayToAddToCopticDay = 1;
      this.eveOf = true;
    }

    this.varusDate = new Map();
    this.varusDate.set(CAL_GREG, calendarDate(language, CAL_GREG, date));
    this.varusDate.set(CAL_COPT, calendarDate(language, CAL_COPT, addDays(date, dayToAddToCopticDay)));

    let tmpEastern = getCopticEasterByGregYear(this.varusDate.get(CAL_GREG).y);
    this.easterDate = calendarDate(this.language, CAL_GREG, new Date(tmpEastern.y, tmpEastern.m - 1, tmpEastern.d));

    this.daysToEastern = Math.ceil((this.easterDate.date.getTime() - this.date.getTime()) / (1000 * 3600 * 24));
    this.lentStart = addDays(this.easterDate.date, -55);
    this.daysBetweenTodayAndLentStart = differenceInDays(this.lentStart, this.date);

    let pentecost = addDays(this.easterDate.date, 50);
    let apostelfast = new Date(this.easterDate.date.getFullYear(), 6, 12);
    this.daysLengthOfApostelFast = differenceInDays(apostelfast, pentecost);

    this.tune = getCurrentTunes(this.varusDate.get(CAL_COPT), this.daysToEastern);

    // Feast
    this.feast = getFeasts(this.varusDate.get(CAL_COPT), this.daysToEastern);

    // Fast
    this.fast = getFasts(this.varusDate.get(CAL_COPT), this.daysToEastern);

    // Season
    this.churchSeason = getSeasons(this.varusDate.get(CAL_COPT), this.dayOfWeek, this.daysToEastern);

    // Katameros
    // the first season controls katameros
    this.katameros = getKatamerosSeason(this.churchSeason[0], this.dayOfWeek);

    // Reading
    this.reading = getReading(this.katameros, this.churchSeason[0], this.daysToEastern, this.varusDate.get(CAL_COPT));
  }

  // Getter Methods

  getDateCoptic() {
    appLog(DebugLevel.DEBUG, fileName, "getDateCoptic");
    return this.varusDate.get(CAL_COPT);
  }

  getDateGregorian() {
    appLog(DebugLevel.DEBUG, fileName, "getDateGregorian");
    return this.varusDate.get(CAL_GREG);
  }

  getDate(calendar) {
    return this.varusDate.get(calendar);
  }
}

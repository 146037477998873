<script>
export default {
  name: "VarusSideMenuLink",
};
</script>
<script setup>
import { IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import { getLanguage } from "../functions/AppData";

defineProps({ element: Object, sideMenuPosition: String });
</script>

<template>
  <ion-item :disabled="element.disabled">
    <ion-label v-if="element.titleDisplay">
      <span v-html="element.titleDisplay[$i18n.locale]"></span>
      <br />
      <span :class="getLanguage('cop').cssTitle" style="font-size: smaller">{{ element.titleDisplay["cop"] }}</span>
    </ion-label>
    <ion-icon
      :slot="sideMenuPosition == 'left' ? 'end' : 'start'"
      :icon="sideMenuPosition == 'left' ? chevronForwardOutline : chevronBackOutline"
      class="component-icon component-icon-primary"
    ></ion-icon>
  </ion-item>
</template>

import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import { DebugLevel, appLog } from "../functions/Logging";

const routes = [
  {
    path: "/",
    redirect: "/home",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/calendar",
    component: () => import("../views/CopticCalendar.vue"),
    name: "calendar",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/settings",
    component: () => import("../views/Settings.vue"),
    name: "settings",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/info",
    component: () => import("../views/Info.vue"),
    name: "info",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/home",
    component: () => import("../views/Home.vue"),
    name: "home",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/booklist",
    redirect: "/booklist/books",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/booklist/:key",
    component: () => import("../views/ListBooks.vue"),
    name: "booklist",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/booklist2/:key",
    component: () => import("../views/ListBooks2.vue"),
    name: "booklist2",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/prayerlist/:key",
    component: () => import("../views/ListPrayers.vue"),
    name: "prayerlist",
    meta: { showRightMenu: true, showDisplayOptionsIcon: true },
  },
  {
    path: "/reading/:key",
    component: () => import("../views/Reading.vue"),
    name: "reading",
    meta: { showRightMenu: true, showDisplayOptionsIcon: true },
  },
  {
    path: "/churchSeason",
    component: () => import("../views/ChurchSeason.vue"),
    name: "churchSeason",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/devinfo",
    component: () => import("../views/DevelopmentInfo.vue"),
    name: "devinfo",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/test",
    component: () => import("../views/TestPage.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: true },
  },
  {
    path: "/test2",
    component: () => import("../views/TestPage2.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: true },
  },
  {
    path: "/seasonAndTuneOfYear",
    component: () => import("../views/SeasonAndTuneOfYear.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/colors",
    component: () => import("../views/Colors.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/grid",
    component: () => import("../views/Grid.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
  {
    path: "/reportbug",
    component: () => import("../views/ReportBug.vue"),
    name: "reportbug",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    appLog(DebugLevel.ROUTER, "[ROUTER]", "from: ", from);
    appLog(DebugLevel.ROUTER, "[ROUTER]", "to: ", to);
    if (to.hash) {
      const el = window.location.href.split("#")[2];
      appLog(DebugLevel.ROUTER, "[ROUTER]", "el: ", el);
      if (el.length && document.getElementById(el) !== null) {
        appLog(DebugLevel.ROUTER, "[ROUTER]", "document.getElementById(el) ", document.getElementById(el));
        document.getElementById(el).scrollIntoView({ inline: "nearest" });
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      document.getElementById("app").scrollIntoView();
    }
  },
  // check this:
  // https://stackoverflow.com/questions/71196755/linking-router-link-and-scrollbehavior-not-working-vue-3-and-ionic-6/71199653#71199653
});

export default router;

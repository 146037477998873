import { readFileFromDisk } from "./GeneralUtils";
import { DebugLevel, appLog } from "../functions/Logging";

const FILE_NAME = "[AppData]";

appLog(DebugLevel.INFO, FILE_NAME, "Reading all AppBooks");
console.time(FILE_NAME + " loadAllBooks");
const readBooks = new Map();
const APP_INFO = readFileAndAddToMap(`app.json`, "config");
const ALL_LANGAUAGES = readFileAndAddToMap(`languages.json`, "config");
const ALL_TRANSLATIONS = readFileAndAddToMap(`bible_translation.json`, "config");
const TUNES = readFileAndAddToMap(`tunes.json`);
const ROLES = readFileAndAddToMap(`roles.json`);
const DEFAULT_TRANSLATIONS = readFileAndAddToMap(`bible_translation_default.json`, "config");
const DEFAULT_DARK_MODE = readFileAndAddToMap(`dark_mode_default.json`, "config");
const DEFAULT_BASE_FONT_SIZE = readFileAndAddToMap(`base_font_size_default.json`, "config");
const READING_TYPES = readFileAndAddToMap(`reading_types.json`, "readings");
export const ALL_BOOKS = {
  BOOKS: readFileAndAddToMap(`books.json`),
  PARAGRAPHS_GENERAL: readFileAndAddToMap(`paragraphs_general.json`),
  AGPEYA: readFileAndAddToMap(`agpeya.json`, "agpeya"),
  AGPEYA1: readFileAndAddToMap(`agpeya1.json`, "agpeya"),
  AGPEYA2: readFileAndAddToMap(`agpeya2.json`, "agpeya"),
  AGPEYA3: readFileAndAddToMap(`agpeya3.json`, "agpeya"),
  AGPEYA4: readFileAndAddToMap(`agpeya4.json`, "agpeya"),
  AGPEYA5: readFileAndAddToMap(`agpeya5.json`, "agpeya"),
  AGPEYA6: readFileAndAddToMap(`agpeya6.json`, "agpeya"),
  AGPEYA7: readFileAndAddToMap(`agpeya7.json`, "agpeya"),
  AGPEYA8: readFileAndAddToMap(`agpeya8.json`, "agpeya"),
  AGPEYA9: readFileAndAddToMap(`agpeya9.json`, "agpeya"),
  AGPEYA10: readFileAndAddToMap(`agpeya10.json`, "agpeya"),
  AGPEYA_PRAYERS: readFileAndAddToMap(`agpeya_prayers.json`, "agpeya"),
  AGPEYA_CONFESSION: readFileAndAddToMap(`agpeya_confession.json`, "agpeya"),
  AGPEYA_REPENTANCE: readFileAndAddToMap(`agpeya_repentance.json`, "agpeya"),
  AGPEYA_COMMUNION: readFileAndAddToMap(`agpeya_communion.json`, "agpeya"),
  AGPEYA_GUIDANCE: readFileAndAddToMap(`agpeya_guidance.json`, "agpeya"),
  AGPEYA_MEALS: readFileAndAddToMap(`agpeya_meals.json`, "agpeya"),
  AGPEYA_VARIOUS: readFileAndAddToMap(`agpeya_various.json`, "agpeya"),
  EUCHOLOGION: readFileAndAddToMap(`euchologion.json`, "euchologion"),
  STBASIL: readFileAndAddToMap(`stbasil.json`, "euchologion"),
  STBASIL1: readFileAndAddToMap(`stbasil1.json`, "euchologion"),
  STBASIL2: readFileAndAddToMap(`stbasil2.json`, "euchologion"),
  STBASIL3: readFileAndAddToMap(`stbasil3.json`, "euchologion"),
  STGREGORY: readFileAndAddToMap(`stgregory.json`, "euchologion"),
  STGREGORY1: readFileAndAddToMap(`stgregory1.json`, "euchologion"),
  STGREGORY2: readFileAndAddToMap(`stgregory2.json`, "euchologion"),
  STGREGORY3: readFileAndAddToMap(`stgregory3.json`, "euchologion"),
  STCYRIL: readFileAndAddToMap(`stcyril.json`, "euchologion"),
  STCYRIL1: readFileAndAddToMap(`stcyril1.json`, "euchologion"),
  STCYRIL2: readFileAndAddToMap(`stcyril2.json`, "euchologion"),
  STCYRIL3: readFileAndAddToMap(`stcyril3.json`, "euchologion"),
  MATINS: readFileAndAddToMap(`matins.json`, "euchologion"),
  VESPER: readFileAndAddToMap(`vesper.json`, "euchologion"),
  LITURGY: readFileAndAddToMap(`liturgy.json`, "euchologion"),
  READINGS: readFileAndAddToMap(`readings.json`, "readings"),
  PSALMODIA: readFileAndAddToMap(`psalmodia.json`, "psalmodia"),
  PSALMODIA_VESPER: readFileAndAddToMap(`psalmodia_vesper.json`, "psalmodia"),
  PSALMODIA_MIDNIGHT: readFileAndAddToMap(`psalmodia_midnight.json`, "psalmodia"),
  PSALMODIA_MATINS: readFileAndAddToMap(`psalmodia_matins.json`, "psalmodia"),
  FUNERAL_GENERAL: readFileAndAddToMap(`funeral_general.json`, "pascha"),
  PASCHA: readFileAndAddToMap(`pascha.json`, "pascha"),

  PASCHA_SUN: readFileAndAddToMap(`pascha_sun.json`, "pascha"),
  PASCHA_SUN_DAY: readFileAndAddToMap(`pascha_sun_day.json`, "pascha"),

  PASCHA_MON: readFileAndAddToMap(`pascha_mon.json`, "pascha"),
  PASCHA_MON_EVE: readFileAndAddToMap(`pascha_mon_eve.json`, "pascha"),
  PASCHA_MON_DAY: readFileAndAddToMap(`pascha_mon_day.json`, "pascha"),

  PASCHA_TUE: readFileAndAddToMap(`pascha_tue.json`, "pascha"),
  PASCHA_TUE_EVE: readFileAndAddToMap(`pascha_tue_eve.json`, "pascha"),
  PASCHA_TUE_DAY: readFileAndAddToMap(`pascha_tue_day.json`, "pascha"),

  PASCHA_WED: readFileAndAddToMap(`pascha_wed.json`, "pascha"),
  PASCHA_WED_EVE: readFileAndAddToMap(`pascha_wed_eve.json`, "pascha"),
  PASCHA_WED_DAY: readFileAndAddToMap(`pascha_wed_day.json`, "pascha"),

  PASCHA_THU: readFileAndAddToMap(`pascha_thu.json`, "pascha"),
  PASCHA_THU_EVE: readFileAndAddToMap(`pascha_thu_eve.json`, "pascha"),
  PASCHA_THU_DAY: readFileAndAddToMap(`pascha_thu_day.json`, "pascha"),

  PASCHA_FRI: readFileAndAddToMap(`pascha_fri.json`, "pascha"),
  PASCHA_FRI_EVE: readFileAndAddToMap(`pascha_fri_eve.json`, "pascha"),
  PASCHA_FRI_DAY: readFileAndAddToMap(`pascha_fri_day.json`, "pascha"),

  PASCHA_SUN_DAY_9: readFileAndAddToMap(`pascha_sun_day_9.json`, "pascha"),
  PASCHA_SUN_DAY_11: readFileAndAddToMap(`pascha_sun_day_11.json`, "pascha"),

  PASCHA_MON_EVE_1: readFileAndAddToMap(`pascha_mon_eve_1.json`, "pascha"),
  PASCHA_MON_EVE_3: readFileAndAddToMap(`pascha_mon_eve_3.json`, "pascha"),
  PASCHA_MON_EVE_6: readFileAndAddToMap(`pascha_mon_eve_6.json`, "pascha"),
  PASCHA_MON_EVE_9: readFileAndAddToMap(`pascha_mon_eve_9.json`, "pascha"),
  PASCHA_MON_EVE_11: readFileAndAddToMap(`pascha_mon_eve_11.json`, "pascha"),
  PASCHA_MON_DAY_1: readFileAndAddToMap(`pascha_mon_day_1.json`, "pascha"),
  PASCHA_MON_DAY_3: readFileAndAddToMap(`pascha_mon_day_3.json`, "pascha"),
  PASCHA_MON_DAY_6: readFileAndAddToMap(`pascha_mon_day_6.json`, "pascha"),
  PASCHA_MON_DAY_9: readFileAndAddToMap(`pascha_mon_day_9.json`, "pascha"),
  PASCHA_MON_DAY_11: readFileAndAddToMap(`pascha_mon_day_11.json`, "pascha"),

  PASCHA_TUE_EVE_1: readFileAndAddToMap(`pascha_tue_eve_1.json`, "pascha"),
  PASCHA_TUE_EVE_3: readFileAndAddToMap(`pascha_tue_eve_3.json`, "pascha"),
  PASCHA_TUE_EVE_6: readFileAndAddToMap(`pascha_tue_eve_6.json`, "pascha"),
  PASCHA_TUE_EVE_9: readFileAndAddToMap(`pascha_tue_eve_9.json`, "pascha"),
  PASCHA_TUE_EVE_11: readFileAndAddToMap(`pascha_tue_eve_11.json`, "pascha"),
  PASCHA_TUE_DAY_1: readFileAndAddToMap(`pascha_tue_day_1.json`, "pascha"),
  PASCHA_TUE_DAY_3: readFileAndAddToMap(`pascha_tue_day_3.json`, "pascha"),
  PASCHA_TUE_DAY_6: readFileAndAddToMap(`pascha_tue_day_6.json`, "pascha"),
  PASCHA_TUE_DAY_9: readFileAndAddToMap(`pascha_tue_day_9.json`, "pascha"),
  PASCHA_TUE_DAY_11: readFileAndAddToMap(`pascha_tue_day_11.json`, "pascha"),

  PASCHA_WED_EVE_1: readFileAndAddToMap(`pascha_wed_eve_1.json`, "pascha"),
  PASCHA_WED_EVE_3: readFileAndAddToMap(`pascha_wed_eve_3.json`, "pascha"),
  PASCHA_WED_EVE_6: readFileAndAddToMap(`pascha_wed_eve_6.json`, "pascha"),
  PASCHA_WED_EVE_9: readFileAndAddToMap(`pascha_wed_eve_9.json`, "pascha"),
  PASCHA_WED_EVE_11: readFileAndAddToMap(`pascha_wed_eve_11.json`, "pascha"),
  PASCHA_WED_DAY_1: readFileAndAddToMap(`pascha_wed_day_1.json`, "pascha"),
  PASCHA_WED_DAY_3: readFileAndAddToMap(`pascha_wed_day_3.json`, "pascha"),
  PASCHA_WED_DAY_6: readFileAndAddToMap(`pascha_wed_day_6.json`, "pascha"),
  PASCHA_WED_DAY_9: readFileAndAddToMap(`pascha_wed_day_9.json`, "pascha"),
  PASCHA_WED_DAY_11: readFileAndAddToMap(`pascha_wed_day_11.json`, "pascha"),

  PASCHA_THU_EVE_1: readFileAndAddToMap(`pascha_thu_eve_1.json`, "pascha"),
  PASCHA_THU_EVE_3: readFileAndAddToMap(`pascha_thu_eve_3.json`, "pascha"),
  PASCHA_THU_EVE_6: readFileAndAddToMap(`pascha_thu_eve_6.json`, "pascha"),
  PASCHA_THU_EVE_9: readFileAndAddToMap(`pascha_thu_eve_9.json`, "pascha"),
  PASCHA_THU_EVE_11: readFileAndAddToMap(`pascha_thu_eve_11.json`, "pascha"),
  PASCHA_THU_DAY_1: readFileAndAddToMap(`pascha_thu_day_1.json`, "pascha"),
  PASCHA_THU_DAY_3: readFileAndAddToMap(`pascha_thu_day_3.json`, "pascha"),
  PASCHA_THU_DAY_6: readFileAndAddToMap(`pascha_thu_day_6.json`, "pascha"),
  PASCHA_THU_DAY_9: readFileAndAddToMap(`pascha_thu_day_9.json`, "pascha"),
  PASCHA_THU_DAY_11: readFileAndAddToMap(`pascha_thu_day_11.json`, "pascha"),

  PASCHA_FRI_EVE_1: readFileAndAddToMap(`pascha_fri_eve_1.json`, "pascha"),
  PASCHA_FRI_EVE_3: readFileAndAddToMap(`pascha_fri_eve_3.json`, "pascha"),
  PASCHA_FRI_EVE_6: readFileAndAddToMap(`pascha_fri_eve_6.json`, "pascha"),
  PASCHA_FRI_EVE_9: readFileAndAddToMap(`pascha_fri_eve_9.json`, "pascha"),
  PASCHA_FRI_EVE_11: readFileAndAddToMap(`pascha_fri_eve_11.json`, "pascha"),

  PASCHA_FRI_DAY_1: readFileAndAddToMap(`pascha_fri_day_1.json`, "pascha"),
  PASCHA_FRI_DAY_3: readFileAndAddToMap(`pascha_fri_day_3.json`, "pascha"),
  PASCHA_FRI_DAY_6: readFileAndAddToMap(`pascha_fri_day_6.json`, "pascha"),
  PASCHA_FRI_DAY_9: readFileAndAddToMap(`pascha_fri_day_9.json`, "pascha"),
  PASCHA_FRI_DAY_11: readFileAndAddToMap(`pascha_fri_day_11.json`, "pascha"),
  PASCHA_FRI_DAY_12: readFileAndAddToMap(`pascha_fri_day_12.json`, "pascha"),
};
console.timeEnd(FILE_NAME + " loadAllBooks");

// Basic Function
/////////////////////////////////////////////////////

/**
 * read json files from disk and attach to Map
 *
 * @param {*} file
 * @returns
 */
function readFileAndAddToMap(file, folder) {
  appLog(DebugLevel.INFO, FILE_NAME + "readFileAndToMap", `Reading ${file}`);
  let book = readFileFromDisk(file, folder);
  readBooks.set(file, book);
  return book;
}

// Basic Files
/////////////////////////////////////////////////////

export function getAppInfo() {
  return APP_INFO;
}

export function getLanguages() {
  return ALL_LANGAUAGES;
}

export function getBooks() {
  return ALL_BOOKS["BOOKS"];
}

export function getBibleTranslations() {
  return ALL_TRANSLATIONS.filter((s) => s.disabled == false);
}

export function getDefaultBibleTranslations() {
  return DEFAULT_TRANSLATIONS;
}

export function getDefaultDarkMode() {
  return DEFAULT_DARK_MODE;
}

export function getDefaultBaseFontSize() {
  return DEFAULT_BASE_FONT_SIZE;
}

export function getRoles() {
  return ROLES;
}

export function getTunes() {
  return TUNES;
}

// Filtered versions
/////////////////////////////////////////////////////

//  filtered Languages

/**
 *
 * @returns object containing UI languages
 */
export function getUILanguages() {
  return ALL_LANGAUAGES.filter((a) => a.uiLanguage == true);
}

/**
 *
 * @returns object containing Text languages
 */
export function getTextLanguages() {
  return ALL_LANGAUAGES.filter((a) => a.textLanguage == true);
}

/**
 *
 * @returns object containing default UI languages
 */
export function getDefaultUiLanguage() {
  return ALL_LANGAUAGES.filter((a) => a.uiLanguageDefault == true);
}

/**
 *
 * @returns object containing default text languages
 */
export function getDefaultTextLanguages() {
  return ALL_LANGAUAGES.filter((a) => a.displayDefault == true);
}

/**
 *
 * @returns language object of the give language key
 */
export function getLanguage(langKey) {
  var result = [];
  result = ALL_LANGAUAGES.filter((obj) => {
    return obj.key === langKey;
  });
  if (result.length > 0) return result[0];
}

//  filtered Books

/**
 * returns the book object for the given book-key
 *
 * @param {*} key
 * @returns
 */
export function getBook(key) {
  if (key === undefined || key == "") return {};
  const val = Object.keys(ALL_BOOKS).filter((objKey) => objKey.toLowerCase() == key.toLowerCase());
  return ALL_BOOKS[val];
}

/**
 * only return the direct object properties (removing the children & prayer)
 *
 * @param {} key
 * @returns
 */
export function getBookInfo(key) {
  const bookObj = Object.keys(ALL_BOOKS).filter((objKey) => objKey.toLowerCase() == key.toLowerCase());
  // copy object by using spread method
  let book = { ...ALL_BOOKS[bookObj] };
  delete book.children;
  delete book.prayers;
  return book;
}

export function getBooksWithBibleUsage() {
  return getBooks().books.filter((b) => b.bible.usage == true);
}

export function getTuneByKey(tuneKey) {
  var result = [];
  result = TUNES.filter((obj) => {
    return obj.key === tuneKey;
  });
  if (result.length > 0) return result[0];
}

/**
 *
 * @returns return all reading types
 */
export function getAllReadingTypes() {
  return READING_TYPES;
}

/**
 *
 * @returns reading types object by the given key
 */
export function getReadingTypeByKey(typeKey) {
  var result = [];
  result = READING_TYPES.filter((obj) => {
    return obj.key === typeKey;
  });
  if (result.length > 0) return result[0];
}

export function getAllLiturgyServices() {
  return ALL_BOOKS["READINGS"];
}

import { DebugLevel, appLog } from "../functions/Logging";
import { getFeasts } from "../seasonservice/FeastService.js";
import { getFasts } from "../seasonservice/FastService.js";
import { getAnnual } from "../seasonservice/AnnualService.js";

export const getSeasons = function (cDate, weekday, daysToEastern) {
  const year = cDate.y;
  const month = cDate.m;
  const day = cDate.d;
  appLog(DebugLevel.DEBUG, "[SeasonService]", `getSeasons for ${year}-${month}-${day}`);

  let seasons = [];

  // Feast
  const feasts = getFeasts(cDate, daysToEastern);

  // Fast
  const fasts = getFasts(cDate, daysToEastern);

  seasons = feasts.concat(fasts);

  if (seasons.length == 0) {
    seasons = getAnnual(weekday);
  } else if (seasons.length > 0) {
    seasons.sort((a, b) => a.sort_order - b.sort_order);
  }

  return seasons;
};

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "App.vue",
};
</script>
<script setup>
import {
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonHeader,
  IonFooter,
  IonToolbar,
  menuController,
} from "@ionic/vue";
import { onBeforeMount, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { DebugLevel, appLog } from "./functions/Logging";
import { hooks } from "./functions/Hooks";
import { useRoute } from "vue-router";

// Varus Components
import VarusMenuLeftSide from "./components/VarusMenuLeftSide.vue";
import VarusChipDate from "./components/VarusChipDate.vue";
import VarusChipDev from "./components/VarusChipDev.vue";
import VarusTableOfPrayers from "./components/VarusTableOfPrayers.vue";

// Pinia Stores
import { useUserSettingsStore } from "./store/userSettingsStore";

// i18n
const { locale } = useI18n({ useScope: "global" });

// Logging
const compName = "[" + getCurrentInstance().type.name + "]";

// Hooks
hooks(compName);

/**
 * Scrolling in the Table of Prayer pane is logged in the y-position is updated in the pinia scroll-Store
 *
 * @param {*} event
 */
function logScrollingMenuRight(event) {
  // scrollStore.YposMenuRight = event.detail.currentY;
  appLog(DebugLevel.SCROLL, compName, "Scrolling to YposMenuRight: " + event.detail.currentY);
}

/**
 * called, when the component has finished setting up its reactive state,
 * but no DOM nodes have been created yet.
 * It is about to execute its DOM render effect for the first time.
 */
onBeforeMount(() => {
  appLog(DebugLevel.HOOKS, compName, "Before Mount");

  // set locale
  locale.value = useUserSettingsStore().uiLanguage.key;
  appLog(DebugLevel.INFO, compName, "Setting locale to: " + locale.value);

  //** commented out because of TypeError
  // scrollStore.$subscribe((mutation) => {
  //   if (mutation.events.key == "YposMenuMain") {
  //     appLog(DebugLevel.SCROLL, compName, "YposMenuMain - Scrolling to: " + scrollStore.YposMenuRight);
  //     // scrolling is here disabled and done by above function logScrollingMenuRight
  //     // ionContentScroll.value.$el.scrollToPoint(0, scrollStore.YposMenuRight);
  //   }
  // });
});
</script>

<template>
  <IonApp>
    <!-- LEFT MENU - Start -->
    <ion-menu
      menuId="left-menu"
      side="start"
      content-id="main-content"
      type="overlay"
      @click="menuController.close('left-menu')"
    >
      <ion-content>
        <VarusMenuLeftSide />
        <ion-footer>
          <ion-toolbar>
            <varus-chip-date cal="gregory" class="chips" />
            <br />
            <varus-chip-date cal="coptic" class="chips" />
            <br />
            <varus-chip-dev class="chips" />
          </ion-toolbar>
        </ion-footer>
      </ion-content>
    </ion-menu>
    <!-- LEFT MENU - Ende -->
    <!-- RIGHT MENU - Start -->
    <ion-menu
      menuId="right-menu"
      side="end"
      contentId="main-content"
      type="overlay"
      :disabled="!useRoute().meta.showRightMenu"
      @click="menuController.close('right-menu')"
    >
      <ion-content :scroll-events="true" @ionScroll="logScrollingMenuRight($event)">
        <ion-header>
          <ion-list>
            <ion-item class="menuTitle">
              <ion-label>
                <!-- Table of Content -->
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-header>
        <VarusTableOfPrayers />
      </ion-content>
    </ion-menu>
    <!-- RIGHT MENU - Ende -->
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </IonApp>
</template>

<style scoped>
.chips {
  color: white;
}

ion-item {
  --min-height: 10px;
}

ion-list-header {
  font-weight: bold;
  min-height: 10px;
  padding-top: 28px;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>

/**
 *
 * @param {*} yourVariable
 * @returns
 */
export function isObject(yourVariable) {
  if (typeof yourVariable === "object" && !Array.isArray(yourVariable) && yourVariable !== null) {
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param {*} ms
 * @returns Converting mseconds to minutes and seconds
 */
export function ms2min(ms) {
  var minutes = Math.floor(ms / 60000);
  var seconds = ((ms % 60000) / 1000).toFixed(0);
  return seconds == 60 ? minutes + 1 + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

/**
 * checks whether given object has an text element of the given language
 *
 * @param {*} obj
 * @param {*} langKey
 * @returns true if given language text is available, else false
 */
export function langContainsText(obj, langKey) {
  if (!Array.isArray(obj[langKey])) return false;
  if (obj[langKey][0] && obj[langKey][0].length > 0) {
    return true;
  } else {
    return false;
  }
}

/**
 * checks whether n is between a and b
 *
 * @param {*} a
 * @param {*} b
 * @param {*} n
 * @returns
 */
export const inRange = function (a, b, n) {
  return (n - a) * (n - b) <= 0;
};

/**
 * Includes method helps to check if element already exist in an array.
 *
 * @param {*} myArray
 * @param {*} element
 */
export const addToArray = (myArray, element) => {
  if (!myArray.includes(element)) {
    myArray.push(element);
  }
};

/**
 * only return key-property of given array
 * @param {*} ary
 * @returns
 */
export const filterKeys = (ary) => {
  return ary.filter((element) => element.key).map((element) => element.key);
};

/**
 * To check for support for a non-English locale (i.e. full-icu or system-icu),
 * Intl.DateTimeFormat can be a good distinguishing factor
 */
export const hasFullICU = () => {
  try {
    const january = new Date(9e8);
    const spanish = new Intl.DateTimeFormat("es", { month: "long" });
    return spanish.format(january) === "enero";
  } catch (err) {
    return false;
  }
};

/**
 * generates random strings with given length
 *
 * @param {*} length
 * @returns
 */
export function generateString(length = 8) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

/**
 * read give file from disk.
 *
 * @param {*} file file to be read from disk
 * @param {*} folder If provided file will be read from specified folder
 * @returns
 */
export function readFileFromDisk(file, folder) {
  // console.log(`Reading file ${file} from ${folder}`);
  let book = "";
  if (typeof folder !== "undefined") {
    book = JSON.parse(JSON.stringify(require(`../data/${folder}/${file}`)));
  } else {
    book = JSON.parse(JSON.stringify(require(`../data/${file}`)));
  }
  return book;
}

/**
 * attach the given object-property to the given object
 *
 * @param {*} objectsToAdd
 * @param {*} object
 * @returns
 */
export function attachPropertyToObject(objectsToAdd, object) {
  Object.entries(objectsToAdd).forEach(([key, value]) => {
    // check whether target prayer already has this object, if yes, than add
    object[key] = value;
    if (key in object === false) {
      object[key] = value;
    } else {
      // TODO: what to do in case key already exists (element should be added and not overwritten)
      object[key] = value;
    }
  });

  return object;
}

export function attachPropertyToObjectIfNotExists(obj, property, value) {
  if (!Object.prototype.hasOwnProperty.call(obj, property)) {
    obj[property] = value;
  }
  return obj;
}

/**
 * returns an array with the given object as its only element
 *
 * @param {*} object
 * @returns
 */
export function toArray(object) {
  let newArray = [];
  newArray[0] = object;
  return newArray;
}

/**
 * special round funtionc for week calculation
 *
 * @param {} d
 * @returns
 */
export function specialRoundUp(d) {
  var result = Math.round(d);
  if (d === 0) {
    return 1;
  } else if (result >= d) {
    return result;
  } else {
    return result + 1;
  }
}

/**
 *  returns a random number between the specified values
 *
 * @param {*} min
 * @param {*} max
 * @returns returned value is no lower than (and may possibly equal) min, and is less than (and not equal) max.
 */
export const getRandomArbitrary = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

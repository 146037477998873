import { DebugLevel, appLog } from "../functions/Logging";
import { nthWeekdayOfCopticMonth } from "../functions/CopticDateTools";
import {
  geKatamerosSunday as geKatamerosSunday,
  geKatamerosWeekdays,
  geKatamerosFeasts,
  geKatamerosHoly50,
  geKatamerosLent,
} from "../services/KatamerosBookService.js";
import { KatamerosBook } from "../enums/KatamerosBookType";

/**
 *
 */
const getKatamerosSeason = function (seas, weekday) {
  appLog(DebugLevel.DEBUG, "[KatamerosSeasonService]", "getKatamerosSeason for season: ", seas);
  appLog(DebugLevel.DEBUG, "[KatamerosSeasonService]", "getKatamerosSeason for weekday: ", weekday);
  const kat_book = seas.kat_book;
  let kat_type = "";
  switch (kat_book) {
    case "annual":
    default:
      if (weekday >= 1 && weekday <= 6) {
        kat_type = KatamerosBook.ANNUAL_WEEKDAYS;
      } else {
        kat_type = KatamerosBook.ANNUAL_SUNDAYS;
      }
      break;
    case "feast":
      kat_type = KatamerosBook.FEASTS;
      break;
    case "lent":
      kat_type = KatamerosBook.LENT;
      break;
    case "holy50":
      kat_type = KatamerosBook.HOLY50;
      break;
  }
  appLog(DebugLevel.DEBUG, "[KatamerosSeasonService]", "kat_type =  to return:", kat_type);
  return kat_type;
};

const getReading = function (katamerosSeason, season, daysToEastern, coptDate) {
  appLog(DebugLevel.DEBUG, "[KatamerosSeasonService]", "getReading for ", katamerosSeason);
  const coptMonth = coptDate.m;
  const coptDay = coptDate.d;
  const sundayOrdinal = nthWeekdayOfCopticMonth(coptDate.d);
  let reading = {};
  switch (katamerosSeason) {
    case KatamerosBook.ANNUAL_SUNDAYS:
      reading = geKatamerosSunday(coptMonth, sundayOrdinal);
      break;
    case KatamerosBook.ANNUAL_WEEKDAYS:
      reading = geKatamerosWeekdays(coptMonth, coptDay);
      break;
    case KatamerosBook.FEASTS:
      reading = geKatamerosFeasts(season.key);
      // see https://kopten.atlassian.net/browse/EGOHM-86
      if (reading !== undefined) {
        reading["title"] = season.titleDisplay.deu;
      }
      break;
    case KatamerosBook.LENT:
      reading = geKatamerosLent(daysToEastern);
      break;
    case KatamerosBook.HOLY50:
      reading = geKatamerosHoly50(daysToEastern);
      break;
    default:
      break;
  }
  // add katameros-title as source of reading
  if (katamerosSeason !== undefined && reading !== undefined) {
    reading.source = katamerosSeason.titleDisplay;
  }
  return reading;
};

export { getKatamerosSeason, getReading };

// Import Basics
import { createApp } from "vue";
import { IonicVue, isPlatform } from "@ionic/vue";
import { Instabug, BugReporting } from "instabug-cordova";
import { Capacitor } from "@capacitor/core";

// Import Components
import App from "./App.vue";
import BaseLayout from "./components/base/BaseLayout.vue";

// Import Plugins & Tools
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";

// ** CSS **
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";

//
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: "8157b2f07ab2a40f169aa700da62b6f0",
  plugins: [new BugsnagPluginVue()],
  otherOptions: "value",
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
});
const bugsnagVue = Bugsnag.getPlugin("vue");

// i18n
const messages = {
  deu: JSON.parse(JSON.stringify(require(`./locales/deu.json`))),
  ara: JSON.parse(JSON.stringify(require(`./locales/ara.json`))),
  eng: JSON.parse(JSON.stringify(require(`./locales/eng.json`))),
};
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "deu",
  messages,
});
const app = createApp(App).use(bugsnagVue).use(i18n).use(IonicVue).use(router).use(createPinia());

app.component("base-layout", BaseLayout);
app.provide(/* key */ "message", /* value */ "Message provided by main.js and injected here");

router.isReady().then(() => {
  app.mount("#app");
});

// Global variables
app.config.globalProperties.$nodeMode = process.env.NODE_ENV;
app.config.globalProperties.$appVersion = process.env.VUE_APP_VERSION;

// Instabug
if (isPlatform("mobile") && Capacitor.getPlatform() != "web") {
  Instabug.start(
    "5f65239fbc10e79f6218aa3da77c5fbd",
    [BugReporting.invocationEvents.none],
    function () {
      console.log("Instabug initialized.");
    },
    function (error) {
      console.log("Instabug could not be initialized - " + error);
    }
  );
  Instabug.setSessionProfilerEnabled(true);
  BugReporting.setEnabledAttachmentTypes(true, true, true, true);
}

import CopticCalendar from "../functions/CopticCalendar/CopticCalendar";

const copticEraDe = "Ära der Märtyrer";
const copticEraEn = "Era of the Martyrs";
const copticMonth = {
  Intl: [
    "Thout",
    "Paopi",
    "Hathor",
    "Koiak",
    "Tobi",
    "Meschir",
    "Paremhat",
    "Paremoude",
    "Paschons",
    "Paoni",
    "Epip",
    "Mesori",
    "Nasie",
  ],
  Arabic: [
    "توت",
    "بابه",
    "هاتور",
    "كياهك",
    "طوبة",
    "أمشير",
    "برمهات",
    "برمودة",
    "بشنس",
    "بؤونة",
    "أبيب",
    "مسرى",
    "نسيئ",
  ],
  DeSoll: [
    "Tut",
    "Babah",
    "Hator",
    "Kiyahk",
    "Tubah",
    "Amschir",
    "Baramhat",
    "Baramuda",
    "Baschans",
    "Ba’una",
    "Abib",
    "Misra",
    "Pikouġi enawot",
  ],
};

/**
 * Convert a Julian Day to regular date in Javascript
 *
 * @param {*} JD
 * @returns
 */
function julianIntToDate(JD) {
  var y = 4716;
  var v = 3;
  var j = 1401;
  var u = 5;
  var m = 2;
  var s = 153;
  var n = 12;
  var w = 2;
  var r = 4;
  var B = 274277;
  var p = 1461;
  var C = -38;
  var f = JD + j + Math.floor((Math.floor((4 * JD + B) / 146097) * 3) / 4) + C;
  var e = r * f + v;
  var g = Math.floor((e % p) / r);
  var h = u * g + w;
  var D = Math.floor((h % s) / u) + 1;
  var M = ((Math.floor(h / s) + m) % n) + 1;
  var Y = Math.floor(e / p) - y + Math.floor((n + m - M) / n);
  return new Date(Y, M - 1, D);
}

/**
 * returns the weekday number value of the first day of the given Month
 *
 * 0 = "Sun"
 * 1 = "Mo"
 * 2 = "Tue"
 * 3 = "Wed"
 * 4 = "Thu"
 * 5 = "Fri"
 * 6 = "Sat"
 */
function getCopticFirstWeekdayOfMonth(cYear, cMonth) {
  const today = new CopticCalendar().newDate(cYear, cMonth, 1);
  return today.dayOfWeek();
}

/**
 * n-th weekday of given coptic day
 */
function nthWeekdayOfCopticMonth(copticDay) {
  const day = copticDay / 7;
  const result = Math.round(day);
  let nthWeekday = 0;
  if (day == 0) {
    nthWeekday = 1;
  } else if (result >= day) {
    nthWeekday = result;
  } else {
    nthWeekday = result + 1;
  }
  return nthWeekday;
}

export {
  copticEraDe,
  copticEraEn,
  copticMonth,
  getCopticFirstWeekdayOfMonth,
  julianIntToDate,
  nthWeekdayOfCopticMonth,
};

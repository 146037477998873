/**
 * TODO: check whether its possible and better to outsource to json-file
 */
export const KatamerosBook = Object.freeze({
  ANNUAL_SUNDAYS: {
    name: "KATA_ANNUAL_SUNDAYS",
    titleDisplay: {
      deu: "Katameros der Sonntage",
      ara: "قطمارس الآحاد",
    },
  },
  ANNUAL_WEEKDAYS: {
    name: "KATA_ANNUAL_WEEKDAYS",
    titleDisplay: {
      deu: "Katameros der Wochentage",
      ara: "قطمارس السنوي الأيام",
    },
  },
  FEASTS: {
    name: "KATA_FEASTS",
    titleDisplay: {
      deu: "Katameros der Festtage",
      ara: "قطمارس الأعياد",
    },
  },
  LENT: {
    name: "KATA_LENT",
    titleDisplay: {
      deu: "Katameros der großen Fastenzeit",
      ara: "قطمارس صوم يونان و الصوم الكبير",
    },
  },
  HOLY50: {
    name: "KATA_HOLY50",
    titleDisplay: {
      deu: "Katameros der Heiligen 50 Tage (Ostern bis Pfingsten)",
      ara: "قطمارس الخمسين المقدسة",
    },
  },
});

import katAnnualSundays from "../data/katameros/kata_annual_sundays.json";
import katAnnualWeekdays from "../data/katameros/kata_annual_weekdays.json";
import katFeasts from "../data/katameros/kata_feasts.json";
import katHoly50 from "../data/katameros/kata_holy50.json";
import katLent from "../data/katameros/kata_lent.json";
/**
 * internal function which checks whether the given reading has an redirectTo element.
 *
 * @param {} dayReading
 * @returns  In the case the redirect element exists, the redirect-reading is taken from weekdays Katameros, else the given one
 */
const getReading = function (dayReading) {
  let reading2return = {};
  if (dayReading === undefined) return;
  if (dayReading.redirectToMonth && dayReading.redirectToDay) {
    reading2return = geKatamerosWeekdays(dayReading.redirectToMonth, dayReading.redirectToDay);
    // use title and description from the original or empty one if original not available
    // TODO: check why the following is not working EGOHM-79
    reading2return["title"] = dayReading.title;
    reading2return["description"] = dayReading.description;
  } else {
    reading2return = dayReading;
  }
  return reading2return;
};

/**
 *
 * @returns readings of the annual Sundays
 */
const geKatamerosSunday = function (month, weekOrdinal) {
  const dayReading = katAnnualSundays.filter((k) => k.copticMonth == month && k.sundayOrdinal == weekOrdinal)[0];
  return getReading(dayReading);
};

/**
 *
 * @returns readings of the annual weekdays
 */
const geKatamerosWeekdays = function (month, day) {
  const dayReading = katAnnualWeekdays.filter((k) => k.copticMonth == month && k.day == day)[0];
  return getReading(dayReading);
};

/**
 *
 * @returns readings of the feasts
 */
const geKatamerosFeasts = function (fKey) {
  const dayReading = katFeasts.filter((k) => k.key.toLowerCase() == fKey.toLowerCase())[0];
  return getReading(dayReading);
};

/**
 *
 * @returns readings of Holy 50
 */
const geKatamerosHoly50 = function (days2eastern) {
  const dayReading = katHoly50.filter((k) => k.daysBeforeEastern == days2eastern)[0];
  return getReading(dayReading);
};

/**
 *
 * @returns readings of the lent
 */
const geKatamerosLent = function (days2eastern) {
  const dayReading = katLent.filter((k) => k.daysBeforeEastern == days2eastern)[0];
  return getReading(dayReading);
};

export { geKatamerosSunday, geKatamerosWeekdays, geKatamerosFeasts, geKatamerosHoly50, geKatamerosLent };

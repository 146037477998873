<script>
export default { name: "BaseSettingList" };
</script>
<script setup>
import { IonList, IonListHeader } from "@ionic/vue";

// PROPS
defineProps({
  settingTitle: String,
});

</script>

<template>
  <ion-list lines="none" inset="false">
    <ion-list-header lines="full" color="secondary">
      {{ settingTitle }}
    </ion-list-header>
    <slot />
  </ion-list>
</template>
<style scoped>
ion-list-header {
  font-weight: bold;
}
</style>